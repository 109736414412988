import { useEffect, useRef, useState } from 'react';

import type React from 'react';

type Props = {
  children: (isConsentGiven: boolean) => React.ReactNode;
};

type AxeptioSDK = {
  on: (event: string, callback: () => void) => void;
  off: (event: string, callback: () => void) => void;
  hasAcceptedVendor: (vendorId: string) => boolean;
};

declare global {
  interface Window {
    _axcb: ((sdk: AxeptioSDK) => void)[];
  }
}

export const AxeptioListener = ({ children }: Props) => {
  const [isConsentGiven, setIsConsentGiven] = useState(false);
  const axeptioSDK = useRef<AxeptioSDK | null>(null);

  useEffect(() => {
    const eventHandler = () => {
      if (axeptioSDK.current) {
        setIsConsentGiven(axeptioSDK.current.hasAcceptedVendor('objectiv'));
      }
    };

    window._axcb = window._axcb || [];
    window._axcb.push((sdk: AxeptioSDK) => {
      axeptioSDK.current = sdk;
      axeptioSDK.current.on('cookies:complete', eventHandler);
    });

    return () => {
      window._axcb = [];
      if (axeptioSDK.current) {
        axeptioSDK.current.off('cookies:complete', eventHandler);
      }
    };
  }, []);

  return children(isConsentGiven);
};
