import { useCallback, useEffect, useRef } from 'react';

import { patchHistoryApi } from './historyApiMonkeyPatching';

const historyEvents = ['popstate', 'pushstate', 'replacestate'];

export const useUrlChange = (handler: (url: string) => void) => {
  patchHistoryApi();

  const urlRef = useRef('');

  const callback = useCallback(() => {
    const newUrl = window.location.pathname;
    if (newUrl !== urlRef.current) {
      handler(newUrl);
      urlRef.current = newUrl;
    }
  }, [handler]);

  useEffect(() => {
    historyEvents.forEach((e) => window.addEventListener(e, callback));
    return () => historyEvents.forEach((e) => window.removeEventListener(e, callback));
  }, [callback]);

  useEffect(callback, []);
};
