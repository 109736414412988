import { ModalProvider } from '@insify/modal';
import { ProspectProvider } from '../ProspectProvider';
import { MarketingParamsProvider } from '../MarketingParamsProvider';
import { PartnerParamsProvider } from '../PartnerParamsProvider';

type TheContextProps = {
  children: React.ReactNode;
};

export const TheContext: React.FC<TheContextProps> = ({ children }) => (
  <ProspectProvider>
    <ModalProvider>
      <MarketingParamsProvider>
        <PartnerParamsProvider>{children}</PartnerParamsProvider>
      </MarketingParamsProvider>
    </ModalProvider>
  </ProspectProvider>
);
