import { useModal } from '@insify/modal';
import { useEffect, useState } from 'react';

import { Modal } from '../../design-system/components';

import * as styles from './ExitPopup.module.css';
import { Carousel } from './components/Carousel';

import type { Popup, PopupButtonProps } from './types';

let popup: Popup | undefined;
let timeout: ReturnType<typeof setTimeout>;
const visitedPopups = new Set<Popup>();

const popupToComponent = (popup: Popup): JSX.Element => {
  switch (popup.__typename) {
    case 'ContentfulComponentExitPopupCarousel':
      return <Carousel content={popup} />;
    default:
      return <></>;
  }
};

export type ExitPopupProps = {
  /** The current URL path used by the component to decide which popup to display */
  path: string;
  /** List of popups received from Contentful */
  popups: Popup[] | null;
  /** The landing pages and the funnels use completely different button components,
  therefore it is necessary for each client app to provide its own buttons */
  PrimaryButton: React.ComponentType<PopupButtonProps>;
  SecondaryButton: React.ComponentType<PopupButtonProps>;
  /** To be able to switch the exit popup feature on and off depending on the environment */
  enabled?: boolean;
};

export const ExitPopup: React.FC<ExitPopupProps> = ({
  path,
  popups,
  PrimaryButton,
  SecondaryButton,
  enabled = true,
}) => {
  if (!enabled) return null;

  const { isModalActive } = useModal();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<JSX.Element>(<></>);

  useEffect(() => {
    if (!isModalActive) {
      popup = popups?.find(({ paths, pathMatchStrategy }) =>
        paths.find((pathItem) => (pathMatchStrategy === 'exact' ? path === pathItem : path.includes(pathItem))),
      );

      if (popup && !visitedPopups.has(popup)) {
        const component = popupToComponent(popup);
        setModalContent(component);

        timeout = setTimeout(() => {
          visitedPopups.add(popup as Popup);
          setIsModalOpen(true);
        }, popup.showAfter * 1000);
      }
    }

    return () => clearInterval(timeout);
  }, [path, popups?.map(({ id }) => id).join(''), isModalActive]);

  return (
    <Modal className={styles.modal} open={isModalOpen} trackingId='exitPopup' onClose={() => setIsModalOpen(false)}>
      <div className={styles.modalContent}>{modalContent}</div>
      <div className={styles.buttons}>
        {popup && (
          <PrimaryButton
            label={popup.primaryButtonLabel}
            target={popup.primaryButtonTarget}
            onClick={() => setIsModalOpen(false)}
          />
        )}
        {popup?.secondaryButtonLabel && popup?.secondaryButtonTarget && (
          <SecondaryButton
            label={popup.secondaryButtonLabel}
            target={popup.secondaryButtonTarget}
            onClick={() => setIsModalOpen(false)}
          />
        )}
      </div>
    </Modal>
  );
};
