import clsx from 'clsx';

import { loader, visible } from './Loader.module.css';
import LoaderIcon from './loader.svg';

export type LoaderProps = {
  className?: string;
  loading?: boolean;
};

export const Loader = ({ className, loading }: LoaderProps): JSX.Element => (
  <LoaderIcon className={clsx(loader, { [visible]: loading }, className)} />
);
