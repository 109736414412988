/**
 * Takes an input string and an array of separator strings and splits the input string into an array at every location where any of the separator strings is found.
 */
export const splitStringAt = (str: string, separator: string[] = []): string[] => {
  if (str.length === 0 || separator.length === 0) return [str];
  const pattern = separator.map((item) => `(${item})`).join('|');
  const regex = new RegExp(pattern, 'g');
  return str.split(regex).filter((chunk) => !!chunk?.trim());
};

/**
 * Takes an input string and a regex and splits the input string into an array at every match
 */
export const splitStringAtRegexMatch = (str: string, regex: RegExp): string[] => {
  if (str.length === 0 || !regex.test(str)) return [str];
  const separator = '___';
  return str
    .replace(regex, (match) => separator + match + separator)
    .split(separator)
    .filter((chunk) => !!chunk?.trim());
};

/**
 * Checks if a given URL points to a file (not 100% reliable but works for our site)
 */
export const isUrlFile = (url: string): boolean => {
  try {
    const lastPathSegment = new URL(url).pathname.split('/').pop();
    return lastPathSegment ? lastPathSegment.indexOf('.') > 0 : false;
  } catch {
    return false;
  }
};

/**
 * Generates a unique string every time `generateUniqueId` is called
 */
export class UniqueIdStorage {
  private storage: { [key: string]: number } = {};

  public generateUniqueId(str: string): string {
    if (this.storage[str]) {
      this.storage[str]++;
      return `${str}-${this.storage[str]}`;
    } else {
      this.storage[str] = 1;
      return str;
    }
  }
}

type SeparatorOptions = {
  keyValueSeparator?: string;
  itemSeparator?: string;
};

/**
 * Parses a list of key-value pairs into an object
 */
export const parseKeyValuePairString = (str: string, options?: SeparatorOptions): Record<string, string> => {
  if (str.length === 0) return {};
  const entries = str.split(options?.itemSeparator || '___');
  const output: Record<string, string> = {};

  entries.forEach((entry) => {
    const [key, value] = entry.trim().split(options?.keyValueSeparator || '---');
    output[key] = value;
  });

  return output;
};

/**
 * Converts a `Record<string, string>` object into a list of key-value pairs
 */
export const convertObjectToKeyValuePairString = (obj: Record<string, string>, options?: SeparatorOptions): string =>
  Object.entries(obj)
    .map(([key, value]) => `${key}${options?.keyValueSeparator || '---'}${value}`)
    .join(options?.itemSeparator || '___');

export const tildeToSoftHyphen = (str: string): string => str.replace(/~/g, String.fromCharCode(173));

export const pascalToKebab = (str: string): string =>
  str
    .replace(/([a-z])([A-Z])/g, '$1-$2') // Insert a dash between lower and upper case letters
    .toLowerCase();
