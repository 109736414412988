// extracted by mini-css-extract-plugin
export var anchorColor = "anchorColor";
export var headingColor = "headingColor";
export var headingFont = "headingFont";
export var headingLg = "headingLg";
export var headingMd = "headingMd";
export var headingSm = "headingSm";
export var headingXl = "headingXl";
export var headingXs = "headingXs";
export var headingXxs = "headingXxs";
export var headingXxxs = "headingXxxs";
export var textColor = "textColor";
export var textFont = "textFont";
export var textLg = "textLg";
export var textLgSemibold = "textLgSemibold";
export var textMd = "textMd";
export var textMdSemibold = "textMdSemibold";
export var textSm = "textSm";
export var textSmSemibold = "textSmSemibold";
export var textXs = "textXs";
export var textXsSemibold = "textXsSemibold";
export var textXxs = "textXxs";
export var textXxsSemibold = "textXxsSemibold";