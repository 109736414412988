export const mergeRefs =
  <TElement>(...refs: React.ForwardedRef<TElement>[]) =>
  (node: TElement) => {
    for (const ref of refs) {
      if (typeof ref === 'function') {
        ref(node);
      } else if (ref) {
        ref.current = node;
      }
    }
  };
