export type PartnerParams = {
  redirectingPartnerName?: string;
};

export const getPartnerParamsFromLocationSearch = (locationSearch: string): PartnerParams => {
  const partnerParams = new URLSearchParams(locationSearch);
  const redirectingPartnerName = partnerParams.get('redirectingPartnerName');
  return {
    ...(redirectingPartnerName && { redirectingPartnerName }),
  };
};

export function getPartnerParams(): PartnerParams | undefined {
  if (typeof window !== 'undefined') {
    return getPartnerParamsFromLocationSearch(window.location.search);
  }
  return undefined;
}
