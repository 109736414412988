// extracted by mini-css-extract-plugin
export var active = "Carousel-module--active--cf972";
export var carousel = "Carousel-module--carousel--a29c9";
export var content = "Carousel-module--content--84623";
export var description = "Carousel-module--description--59218";
export var image = "Carousel-module--image--e972b";
export var raster = "Carousel-module--raster--d7b1e";
export var slide = "Carousel-module--slide--e4ceb";
export var step = "Carousel-module--step--e88a5";
export var stepper = "Carousel-module--stepper--1c0a6";
export var title = "Carousel-module--title--62d24";