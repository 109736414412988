import last from 'lodash.last';
import { v4 as uuid, validate } from 'uuid';

const removeParamFromUrl = (urlParams: URLSearchParams, key: string): void => {
  urlParams.delete(key);
  const updatedUrl = `${window.location.origin}${window.location.pathname}${
    urlParams.values.length ? '?' + urlParams : ''
  }`;
  window.history.replaceState(null, document.title, updatedUrl);
};

let memoryStorage: Record<string, string> = {};

const getBrowserStorage = (): Pick<Storage, 'getItem' | 'setItem'> => {
  try {
    return window.sessionStorage;
  } catch (e) {
    return {
      getItem: (key: string) => memoryStorage[key],
      setItem: (key: string, value: string) => {
        memoryStorage[key] = value;
      },
    };
  }
};

const getProspectIdFromUrl = (): string | undefined => {
  const urlParams = new URLSearchParams(window.location.search);
  const prospectIdFromUrl = urlParams.get('prospectId');
  return last(prospectIdFromUrl?.split('/'));
};

const getProspectIdFromSessionStorage = (): string | undefined => {
  return getBrowserStorage().getItem('prospectId') || undefined;
};

const setProspectIdInSessionStorage = (prospectId: string): void => {
  return getBrowserStorage().setItem('prospectId', prospectId);
};

const createProspectId = (): string => {
  return uuid();
};

const validateProspectId = (prospectId: string): boolean => {
  return validate(prospectId);
};

/**
 * This function tries to return a `prospectId`:
 * 1. from the URL
 * 2. from session storage
 * 3. or it generates a new one if the first 2 fails.
 */
export const initializeProspectId = (): string => {
  const prospectIdFromUrl = getProspectIdFromUrl();

  if (prospectIdFromUrl && typeof window !== 'undefined') {
    removeParamFromUrl(new URLSearchParams(window.location.search), 'prospectId');

    if (validateProspectId(prospectIdFromUrl)) {
      setProspectIdInSessionStorage(prospectIdFromUrl);
      return prospectIdFromUrl;
    }
  }

  const prospectIdFromSessionStorage = getProspectIdFromSessionStorage();

  if (!prospectIdFromSessionStorage || !validateProspectId(prospectIdFromSessionStorage)) {
    const prospectId = createProspectId();
    setProspectIdInSessionStorage(prospectId);
    return prospectId;
  }

  return prospectIdFromSessionStorage;
};
