// process.env.NODE_ENV equals "production" when we build Gatsby project,
// so it is not a good idea to use this variable to determine the environment.
//
// process.env.GATSBY_APP_ENVIRONMENT on the other hand
// is set to "local" on local dev environment,
// "dev" during deploy on staging and "prod" on production environment.

import { COUNTRY } from '~globals';

type GatsbyAppEnvironment = 'local' | 'dev' | 'prod';

const defaultGatsbyAppEnvironment = 'local';
const envGatsbyAppEnvironment = process.env.GATSBY_APP_ENVIRONMENT;

export const gatsbyAppEnvironment: GatsbyAppEnvironment =
  envGatsbyAppEnvironment === undefined
    ? defaultGatsbyAppEnvironment
    : (envGatsbyAppEnvironment as GatsbyAppEnvironment);

export const isProd = gatsbyAppEnvironment === 'prod';
export const isLocalDev = gatsbyAppEnvironment === 'local';
export const isGermany = COUNTRY === 'de';
