import * as React from 'react';
import { createContext, useContext, useEffect, useState } from 'react';
import { getMarketingParams } from '@insify/ui-utils';
import { COUNTRY } from '~globals';

import { useCommandsNl } from '~hooks/useCommandsNl';
import { useProspectId } from '../ProspectProvider';

import type { MarketingParams } from '@insify/ui-utils';

type MarketingParamsContextValue = {
  marketingParams?: MarketingParams;
};

type MarketingParamsProviderProps = {
  children: React.ReactNode;
};

const MarketingParamsContext = createContext<MarketingParamsContextValue>({});

export const MarketingParamsProvider: React.FC<MarketingParamsProviderProps> = ({ children }): JSX.Element => {
  const [marketingParams] = useState<MarketingParams | undefined>(getMarketingParams());
  const { prospectId } = useProspectId();
  const { sendCommand } = useCommandsNl();

  const sendLandOnPage = async (): Promise<void> => {
    await sendCommand('landOnPage', {
      path: window.location.pathname,
      utm: marketingParams?.utm,
      externalCustomerId: marketingParams?.finomClientId,
    });

    if (window.location.pathname.includes('/bol')) {
      void sendCommand('usePartner', {
        partner: 'bol',
      });
    }
  };

  useEffect(() => {
    prospectId && COUNTRY === 'nl' && sendLandOnPage();
  }, [prospectId]);

  return <MarketingParamsContext.Provider value={{ marketingParams }}>{children}</MarketingParamsContext.Provider>;
};

export const useMarketingParams = (): MarketingParamsContextValue => {
  const context = useContext(MarketingParamsContext);
  if (context === undefined) throw new Error('useMarketingParams must be used within a MarketingParamsProvider');
  return context;
};
