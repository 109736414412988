import { useEffect, useState } from 'react';

export const useBreakpoint = (query: string): boolean => {
  if (typeof window === 'undefined') return false;

  const mediaQueryList: MediaQueryList = matchMedia(query);

  const [matches, setMatches] = useState<boolean>(mediaQueryList.matches);

  const changeHandler = (event: MediaQueryListEvent) => setMatches(event.matches);

  useEffect(() => {
    mediaQueryList.addEventListener
      ? mediaQueryList.addEventListener('change', changeHandler)
      : mediaQueryList.addListener(changeHandler); // to support < iOS 14

    return () => {
      mediaQueryList.removeEventListener
        ? mediaQueryList.removeEventListener('change', changeHandler)
        : mediaQueryList.removeListener(changeHandler);
    };
  }, []);

  return matches;
};
