import { TrackedLinkContext } from '@objectiv/tracker-react';
import { Link } from 'gatsby';
import React from 'react';

import type { LinkContextWrapperProps } from '@objectiv/tracker-react';
import type { GatsbyLinkProps } from 'gatsby';
import type { ComponentProps } from 'react';

/* type InternalLinkProps = LinkProps<unknown> & {
  activeClassName?: string;
};

const InternalLink = React.forwardRef<HTMLAnchorElement, InternalLinkProps>(
  ({ className, activeClassName, ...props }, ref) => (
    <Link
      {...props}
      ref={ref}
      getProps={({ isCurrent }) => ({
        className: clsx(className, isCurrent && activeClassName),
      })}
    />
  ),
); */

export type TrackedLinkProps = Omit<LinkContextWrapperProps, 'href' | 'id'> &
  GatsbyLinkProps<unknown> & {
    trackingId?: string;
  };

export const TrackedLink = React.forwardRef<HTMLAnchorElement, TrackedLinkProps>(
  ({ to, trackingId, ...props }, ref) => (
    <TrackedLinkContext<ComponentProps<typeof Link>>
      {...props}
      ref={ref}
      to={to}
      href={to}
      objectiv={{ Component: Link, id: trackingId || props.id, waitUntilTracked: true }}
    />
  ),
);
