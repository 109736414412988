import { ExitPopup as InsifyExitPopup } from '@insify/exit-popup';
import { useLocation } from '@reach/router';
import React from 'react';

import { COUNTRY } from '~globals';
import { ButtonLink } from '~components/ButtonLink';
import { useSiteConfig } from '~hooks/useSiteConfig';

import type { PopupButtonProps } from '@insify/exit-popup';
import type { SharedButtonProps } from '~components/ButtonLink';

const getButton =
  ({ outline }: Pick<SharedButtonProps, 'outline'>) =>
  ({ label, target, onClick }: PopupButtonProps) => (
    <ButtonLink href={target} target='_blank' outline={outline} onClick={onClick}>
      {label}
    </ButtonLink>
  );

const DarkButton = React.memo(getButton({ outline: false }));
const OutlineButton = React.memo(getButton({ outline: true }));

export const ExitPopup: React.FC = (): JSX.Element => {
  const { pathname } = useLocation();
  const { exitPopups } = useSiteConfig(COUNTRY);

  return (
    <InsifyExitPopup path={pathname} popups={exitPopups} PrimaryButton={DarkButton} SecondaryButton={OutlineButton} />
  );
};
