import { TrackedAnchor } from '@objectiv/tracker-react';
import React, { forwardRef } from 'react';

import { internalLinkRegex } from '~globals';
import { useFunnelUrl } from '~hooks/useFunnelUrl';
import { isUrlFile } from '~utils/string';
import { TrackedLink } from '../TrackedLink';

import type { ForwardedRef } from 'react';

export type BaseLinkProps = React.ComponentPropsWithoutRef<'a'> & {
  href: string;
  activeClassName?: string;
  trackingId?: string;
};

export const BaseLink = forwardRef(
  (
    { children, href, target = '_self', rel, trackingId, className, activeClassName, ...props }: BaseLinkProps,
    ref?: ForwardedRef<HTMLAnchorElement>,
  ) => {
    const { formatFunnelLink } = useFunnelUrl();
    const isInternalLink = internalLinkRegex.test(href) || href.startsWith('/');

    if (isInternalLink) {
      const hrefWithTrailingSlash =
        href.endsWith('/') || isUrlFile(href) || href.split('/').pop()?.includes('.') ? href : `${href}/`;
      return (
        <TrackedLink
          ref={ref}
          trackingId={trackingId}
          className={className}
          activeClassName={activeClassName}
          to={hrefWithTrailingSlash.replace(internalLinkRegex, '/')}
          {...props}
        >
          {children}
        </TrackedLink>
      );
    }

    return (
      <TrackedAnchor
        ref={ref}
        objectiv={{ id: trackingId || props.id }}
        className={className}
        href={formatFunnelLink(href)}
        target={target}
        rel={rel}
        {...props}
      >
        {children}
      </TrackedAnchor>
    );
  },
);
