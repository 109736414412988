import { createContext, useContext, useState } from 'react';

type ModalContextValue = {
  isModalActive: boolean;
  setIsModalActive: React.Dispatch<React.SetStateAction<boolean>>;
};

const ModalContext = createContext<ModalContextValue | undefined>(undefined);

type ModalProviderProps = {
  children: React.ReactNode;
};

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const [isModalActive, setIsModalActive] = useState<boolean>(false);
  return <ModalContext.Provider value={{ isModalActive, setIsModalActive }}>{children}</ModalContext.Provider>;
};

export const useModal = (): ModalContextValue => {
  const context = useContext(ModalContext);
  if (!context) throw new Error(`'useModal' must be used inside a 'ModalProvider'`);
  return context;
};
