import { useOnFirstMount } from '@insify/react-hooks';
import { getPortalDOM, mergeRefs } from '@insify/ui-utils';
import { TrackedOverlayContext, useVisibleEventTracker } from '@objectiv/tracker-react';
import { animated, easings, useTransition } from '@react-spring/web';
import clsx from 'clsx';
import FocusTrap from 'focus-trap-react';
import { forwardRef, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

import * as styles from './BaseModal.module.css';
import { useModal } from './ModalProvider';

import type React from 'react';

type TrackingProps = {
  trackingId: string;
};

export type BaseModalProps = {
  open: boolean;
  children?: React.ReactNode;
  /* onClose is called if you press ESC, click outside of the modal, or click the close button */
  onClose: () => void;
  className?: string;
  backdropClass?: string;
  locked?: boolean;
  'data-testid'?: string;
} & TrackingProps;

const InnerModal = forwardRef<HTMLDivElement, Omit<BaseModalProps, 'trackingId'>>(
  ({ open, children, onClose, className, backdropClass, locked, 'data-testid': testId }, ref): React.JSX.Element => {
    if (typeof window === 'undefined') return <></>;

    const trackVisibleEvent = useVisibleEventTracker();
    useOnFirstMount(() => {
      if (open) {
        void trackVisibleEvent();
      }
    });

    const dialogRef = useRef<HTMLDivElement>(null);
    const bodyScrollPosition = useRef<number>();
    const { setIsModalActive } = useModal();

    const transitions = useTransition(open, {
      from: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        transform: `translateY(${window.innerHeight / 2}px)`,
        opacity: 0,
      },
      enter: {
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        transform: 'translateY(0px)',
        opacity: 1,
      },
      leave: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        transform: `translateY(${window.innerHeight / 2}px)`,
        opacity: 0,
      },
      initial: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        transform: `translateY(${window.innerHeight / 2}px)`,
        opacity: 0,
      },
      config: {
        duration: 250,
        easing: easings.easeOutCubic,
      },
    });

    useEffect(() => {
      setIsModalActive(open);

      if (open) {
        bodyScrollPosition.current = document.documentElement.scrollTop;
        document.body.style.overflowY = 'hidden';
      } else {
        if (bodyScrollPosition.current !== undefined) {
          document.documentElement.scrollTop = bodyScrollPosition.current;
        }
      }

      return () => {
        // Only run the cleanup fn if the state was open before the re-render or component destroy.
        // This is required because during funnel navigation two page are rendered for a short
        // prediod of time and if the exiting page has a modal component on it, it's cleanup fn
        // would run and change the body overflow after the new page finished animating.
        // This causes issues if the new page enters with a modal already open.
        if (open) {
          document.body.style.overflowY = 'initial';
        }
      };
    }, [open]);

    const closeOnBackdropClick: React.MouseEventHandler = (event) => {
      if (locked) return;

      const dialog = dialogRef.current as HTMLDivElement;
      const target = event.target as HTMLElement;
      if (!dialog.contains(target)) onClose();
    };

    const closeOnEscape: React.KeyboardEventHandler = (event) => {
      if (locked) return;

      if (event.key === 'Escape') onClose();
    };

    return ReactDOM.createPortal(
      transitions(
        ({ backgroundColor, transform, opacity }, open) =>
          open && (
            <FocusTrap>
              <animated.div
                className={clsx(styles.backdrop, backdropClass)}
                style={{ backgroundColor }}
                onClick={closeOnBackdropClick}
                data-testid={testId}
              >
                <animated.div
                  className={clsx(styles.dialog, className)}
                  style={{ transform, opacity }}
                  ref={mergeRefs(ref, dialogRef)}
                  role='dialog'
                  tabIndex={0}
                  onKeyDown={closeOnEscape}
                >
                  {children}
                </animated.div>
              </animated.div>
            </FocusTrap>
          ),
      ),
      getPortalDOM('modal'),
    );
  },
);

export const BaseModal = forwardRef<HTMLDivElement, BaseModalProps>(
  ({ trackingId, ...props }: BaseModalProps, ref): React.JSX.Element => {
    return (
      <TrackedOverlayContext
        {...props}
        ref={ref}
        objectiv={{
          id: trackingId,
          Component: InnerModal,
          isVisible: props.open,
        }}
      />
    );
  },
);
