type PortalDOMId = 'modal' | 'funnel-navigation-footer';

const getPortalDOM = (id: PortalDOMId): HTMLElement => {
  const portalDOM = document.getElementById(id);
  if (!portalDOM) {
    throw new Error(`Portal DOM with id ${id} not found`);
  }
  return portalDOM;
};

export { getPortalDOM };
