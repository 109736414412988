import { getMarketingParams } from '@insify/ui-utils';

import type { IdentityContextAttributes } from '@objectiv/plugin-identity-context';

export function getDistributionIdentityContext(): IdentityContextAttributes[] {
  const { utm: _, ...nonUtmDistributionParams } = getMarketingParams() || {};
  return Object.entries(nonUtmDistributionParams).map(([distributionId, distributionValue]) => ({
    id: distributionId,
    value: distributionValue,
  }));
}

export function getProspectIdentityContext(prospectId?: string): IdentityContextAttributes[] {
  return prospectId ? [{ id: 'prospectId', value: prospectId }] : [];
}

export function getUserIdentityContext(userId?: string): IdentityContextAttributes[] {
  return userId ? [{ id: 'cognitoUserId', value: userId }] : [];
}

export const getTrackingUserIdentityContext = (trackingUserId?: string): IdentityContextAttributes[] => {
  return trackingUserId ? [{ id: 'trackingUserId', value: trackingUserId }] : [];
};

export function importDevtoolsDynamically(): void {
  import('@objectiv/developer-tools');
}
