import { graphql, useStaticQuery } from 'gatsby';

import type { Country } from '~models/country';
import type { Popup } from '@insify/exit-popup';

type ContentfulSiteConfig = {
  exitPopups: Popup[];
};

type SiteConfig = Record<Country, ContentfulSiteConfig>;

const query = graphql`
  query SiteConfigQuery {
    nl: contentfulSiteConfig(contentfulid: { eq: "nl" }) {
      ...SiteConfig
    }
    de: contentfulSiteConfig(contentfulid: { eq: "de" }) {
      ...SiteConfig
    }
    fr: contentfulSiteConfig(contentfulid: { eq: "fr" }) {
      ...SiteConfig
    }
  }

  fragment SiteConfig on ContentfulSiteConfig {
    exitPopups {
      __typename
      name
      paths
      pathMatchStrategy
      showAfter
      slides {
        title
        description
        image {
          url
          mimeType
        }
      }
      animationInterval
      primaryButtonLabel
      primaryButtonTarget
      secondaryButtonLabel
      secondaryButtonTarget
    }
  }
`;

export const useSiteConfig = (country: Country) => useStaticQuery<SiteConfig>(query)[country];
