import React from 'react';
import { Script } from 'gatsby';
import { TheContext } from '~components/TheContext';
import { GTM_URL } from '~globals';
import { wrapWithProvider } from '~components/wrapWithProvider';

import type { GatsbyBrowser } from 'gatsby';
import type { PageContext } from '~components/wrapWithProvider';

require('@insify/design-system/colors.global.css');
require('~styles/global.css');

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => (
  <TheContext>
    {element}
    <Script src={GTM_URL} />
    <Script id='gtm-init'>
      {`
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ 'platform': 'funnel' });
        window.dataLayer.push({ 'gtm.start': new Date().getTime(), 'event': 'gtm.js' });
      `}
    </Script>
  </TheContext>
);

export const wrapPageElement: GatsbyBrowser<unknown, PageContext>['wrapPageElement'] = wrapWithProvider;

let nextRoute: string;

export const onPreRouteUpdate: GatsbyBrowser['onPreRouteUpdate'] = ({ location }) => {
  nextRoute = location.pathname;
};

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({ location }) => {
  updateCanonicalURL(location);

  if (process.env.NODE_ENV === `production`) {
    setTimeout(() => {
      window?.dataLayer?.push({ event: 'route-change' });
    }, 50);
  }
};

window.addEventListener('unhandledrejection', ({ reason }) => {
  if (/loading chunk \d* failed./i.test(reason)) {
    if (nextRoute) window.location.pathname = nextRoute;
  }
});

const updateCanonicalURL = (location: Location) => {
  const element = document.querySelector(`link[rel='canonical']`);

  if (!element) return;

  const existingValue = element.getAttribute(`href`);
  const baseProtocol = element.getAttribute(`data-baseProtocol`);
  const baseHost = element.getAttribute(`data-baseHost`);

  if (existingValue && baseProtocol && baseHost) {
    const value = `${baseProtocol}//${baseHost}${location.pathname}${location.hash}`;
    element.setAttribute(`href`, value);
  }
};
