interface PushStateEventInit extends EventInit {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  state?: any;
}

// Event is not defined in the Node.js environment, so we don't use it on the top level of this file.
export const makePushStateEvent = (type: string, eventInitDict?: PushStateEventInit) => {
  class PushStateEvent extends Event {
    readonly state: any;

    constructor(type: string, eventInitDict?: PushStateEventInit) {
      super(type, eventInitDict);
      this.state = eventInitDict?.state || null;
    }
  }

  return new PushStateEvent(type, eventInitDict);
};

interface ReplaceStateEventInit extends EventInit {
  state?: any;
}

export const makeReplaceStateEvent = (type: string, eventInitDict?: ReplaceStateEventInit) => {
  class ReplaceStateEvent extends Event {
    readonly state: any;

    constructor(type: string, eventInitDict?: ReplaceStateEventInit) {
      super(type, eventInitDict);
      this.state = eventInitDict?.state || null;
    }
  }

  return new ReplaceStateEvent(type, eventInitDict);
};

let patchedAlready = false;
let nativePushState: typeof window.history.pushState;
let nativeReplaceState: typeof window.history.replaceState;

/**
 * Monkey-patch the history API to dispatch pushstate and replacestate events
 * when the corresponding methods are called.
 */
export const patchHistoryApi = () => {
  if (!patchedAlready) {
    nativePushState = window.history.pushState;
    const monkeyPatchedPushState: typeof nativePushState = (...args) => {
      nativePushState.call(window.history, ...args);
      window.dispatchEvent(makePushStateEvent('pushstate', { state: args[0] }));
    };
    window.history.pushState = monkeyPatchedPushState;

    nativeReplaceState = window.history.replaceState;
    const monkeyPatchedReplaceState: typeof nativeReplaceState = (...args) => {
      nativeReplaceState.call(window.history, ...args);
      window.dispatchEvent(makeReplaceStateEvent('replacestate', { state: args[0] }));
    };
    window.history.replaceState = monkeyPatchedReplaceState;

    patchedAlready = true;
  }
};

export const rollbackHistoryApiPatching = () => {
  if (patchedAlready) {
    window.history.pushState = nativeReplaceState;
    window.history.replaceState = nativeReplaceState;

    patchedAlready = false;
  }
};
