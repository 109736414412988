import * as React from 'react';
import { getPartnerParams, type PartnerParams } from '@insify/ui-utils';

type PartnerState = {
  partnerParams?: PartnerParams;
};

type Props = { children: React.ReactNode };
const partnerContext = React.createContext<PartnerState | undefined>(undefined);

function PartnerParamsProvider({ children }: Props): JSX.Element {
  const [partnerParams] = React.useState<PartnerParams | undefined>(getPartnerParams());

  return <partnerContext.Provider value={{ partnerParams }}>{children}</partnerContext.Provider>;
}

function usePartnerParams() {
  const context = React.useContext(partnerContext);
  if (context === undefined) {
    throw new Error('usePartnerParams must be used within a PartnerParamsProvider');
  }
  return context;
}

export { PartnerParamsProvider, usePartnerParams };
