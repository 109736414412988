// extracted by mini-css-extract-plugin
export var bodyCopy = "Button-module--bodyCopy--8cbbb";
export var button = "Button-module--button--5ae7c";
export var icon = "Button-module--icon--a8cc4";
export var iconOnly = "Button-module--iconOnly--43d0c";
export var loader = "Button-module--loader--f8d2e";
export var loading = "Button-module--loading--bbb6a";
export var neutral = "Button-module--neutral--a3158";
export var primary = "Button-module--primary--7ebfe";
export var quaternary = "Button-module--quaternary--b2298";
export var secondary = "Button-module--secondary--98e10";
export var tertiary = "Button-module--tertiary--b9374";