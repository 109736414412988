import { useMemo } from 'react';

import { funnelLinkRegex } from '~globals';
import { usePartnerParams } from '~components/PartnerParamsProvider';
import { useMarketingParams } from '~components/MarketingParamsProvider';
import { useProspectId } from '~components/ProspectProvider';

import type { MarketingParams, PartnerParams } from '@insify/ui-utils';

type UseFunnelUrlReturn = {
  formatFunnelLink: (link: string) => string;
};

export const getFunnelQuery = (
  marketingParams?: MarketingParams,
  prospectId?: string,
  partnerParams?: PartnerParams,
): Record<string, string> => {
  const queryObj: Record<string, string> = {};

  if (prospectId) queryObj.prospectId = prospectId;
  if (marketingParams?.utm?.source) queryObj.utm_source = marketingParams.utm.source;
  if (marketingParams?.utm?.medium) queryObj.utm_medium = marketingParams.utm.medium;
  if (marketingParams?.utm?.campaign) queryObj.utm_campaign = marketingParams.utm.campaign;
  if (marketingParams?.utm?.term) queryObj.utm_term = marketingParams.utm.term;
  if (partnerParams?.redirectingPartnerName) queryObj.redirectingPartnerName = partnerParams.redirectingPartnerName;

  return queryObj;
};

export const useFunnelUrl = (): UseFunnelUrlReturn => {
  const { prospectId } = useProspectId();
  const { marketingParams } = useMarketingParams();
  const { partnerParams } = usePartnerParams();

  const funnelQuery: Record<string, string> = useMemo(
    () => getFunnelQuery(marketingParams, prospectId, partnerParams),
    [marketingParams, prospectId, partnerParams],
  );

  /**
   * Automatically add prospectId and marketing params to url if it's a funnel URL (prod, dev, staging etc.)
   */
  const formatFunnelLink = (link: string): string => {
    try {
      if (funnelLinkRegex.test(link)) {
        const url = new URL(link);
        Object.entries(funnelQuery).forEach(([name, value]) => url.searchParams.append(name, value));
        return url.toString();
      } else {
        return link;
      }
    } catch (err) {
      return link;
    }
  };

  return { formatFunnelLink };
};
