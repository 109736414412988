import { TrackedAnchor, TrackedButton } from '@objectiv/tracker-react';
import clsx from 'clsx';

import '../../colors.global.css';
import { textMdSemibold } from '../../typography';
import { Loader } from '../Loader';

import * as styles from './Button.module.css';

import type { TrackingProps } from '../types';
import type React from 'react';
import type { SetRequired } from 'type-fest';

// https://www.figma.com/file/ssGmau7DMZsdIX7F6k0lGk/%E2%9A%A1%EF%B8%8F-Design-System?type=design&node-id=32-871&t=e1ZTK3gxb0o6tvFs-0
type ButtonType = SetRequired<Omit<React.ComponentPropsWithoutRef<'button'>, 'children'>, 'type'>;
type AnchorType = SetRequired<Omit<React.ComponentPropsWithoutRef<'a'>, 'children'>, 'href'>;
type IconOnly = {
  leftIcon: React.ReactElement;
};
type ChildrenAndOptionalIcons = {
  leftIcon?: React.ReactElement;
  children: React.ReactNode;
};

export type ButtonProps = {
  kind: 'primary' | 'secondary' | 'tertiary' | 'quaternary' | 'neutral';
  loading?: boolean;
  rightIcon?: React.ReactElement;
} & TrackingProps &
  // either button or anchor
  // to use button specify "type" property
  // to use anchor specify "href" property
  (ButtonType | AnchorType) &
  (IconOnly | ChildrenAndOptionalIcons);

export const Button = ({
  className,
  kind,
  loading,
  leftIcon,
  rightIcon,
  trackingId,
  ...props
}: ButtonProps): JSX.Element => {
  const componentClassName = clsx(styles.button, loading && styles.loading, styles[kind], className);

  const componentBody = (
    <>
      {leftIcon && (
        <leftIcon.type
          {...leftIcon.props}
          className={clsx(leftIcon.props.className, styles.icon)}
          data-button-element='left-icon'
        />
      )}
      {'children' in props && (
        <>
          <span className={clsx(textMdSemibold, styles.bodyCopy)} data-button-element='text'>
            {props.children}
          </span>
          {rightIcon && (
            <rightIcon.type
              {...rightIcon.props}
              className={clsx(rightIcon.props.className, styles.icon)}
              data-button-element='right-icon'
            />
          )}
        </>
      )}
      <Loader loading={loading} className={styles.loader} />
    </>
  );

  // A bit repetitive, but this way typescript narrows types properly
  if ('href' in props) {
    return (
      <TrackedAnchor {...props} className={componentClassName} objectiv={{ id: trackingId }}>
        {componentBody}
      </TrackedAnchor>
    );
  }
  const disabled = props.disabled || loading;
  return (
    <TrackedButton {...props} className={componentClassName} disabled={disabled} objectiv={{ id: trackingId }}>
      {componentBody}
    </TrackedButton>
  );
};
