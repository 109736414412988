import { initializeProspectId } from '~utils/prospect';
import React, { createContext, useContext, useEffect, useState } from 'react';

type ProspectContextValue = {
  prospectId?: string;
  setProspectId: React.Dispatch<string>;
};

type ProspectProviderProps = {
  children: React.ReactNode;
};

const ProspectContext = createContext<ProspectContextValue | undefined>(undefined);

export const ProspectProvider: React.FC<ProspectProviderProps> = ({ children }): JSX.Element => {
  const [prospectId, setProspectId] = useState<string>();

  useEffect(() => {
    setProspectId(initializeProspectId());
  }, []);

  return <ProspectContext.Provider value={{ prospectId, setProspectId }}>{children}</ProspectContext.Provider>;
};

export const useProspectId = (): ProspectContextValue => {
  const context = useContext(ProspectContext);
  if (context === undefined) throw new Error('useProspectId must be used within a ProspectProvider');
  return context;
};
