import { useEffect, useRef } from 'react';

export const useCallOnceIfTrue = (callback: () => void, condition: boolean) => {
  const hasBeenCalled = useRef(false);

  useEffect(() => {
    if (!hasBeenCalled.current && condition) {
      callback();
      hasBeenCalled.current = true;
    }
  }, [condition]);
};
