import { headingSm, textMd } from '@insify/design-system/typography';
import clsx from 'clsx';
import { useEffect, useState } from 'react';

import * as styles from './Carousel.module.css';

import type { CarouselPopup, Slide } from '../../types';

let interval: ReturnType<typeof setInterval>;

const CarouselSlide: React.FC<{ slide: Slide }> = ({ slide: { image, title, description } }) => (
  <div className={styles.slide}>
    <img
      className={clsx(styles.image, image.mimeType === 'image/svg+xml' ? styles.svg : styles.raster)}
      src={`${image.url}?w=640`}
      alt=''
    />
    <h2 className={clsx(headingSm, styles.title)}>{title}</h2>
    <p className={clsx(textMd, styles.description)}>{description}</p>
  </div>
);

type CarouselProps = {
  children?: string;
  content: CarouselPopup;
};

export const Carousel: React.FC<CarouselProps> = ({ content }) => {
  const [activeSlide, setActiveSlide] = useState<number>(0);

  useEffect(() => {
    interval = setInterval(
      () => setActiveSlide((value) => (value < content.slides.length - 1 ? value + 1 : 0)),
      content.animationInterval * 1000,
    );

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={styles.carousel}>
      {content.slides.length === 1 ? (
        <CarouselSlide slide={content.slides[0]} />
      ) : (
        <>
          <div className={styles.content} style={{ transform: `translate3d(-${activeSlide * 100}%, 0px, 0px)` }}>
            {content.slides.map((slide) => (
              <CarouselSlide key={slide.title} slide={slide} />
            ))}
          </div>
          <div className={styles.stepper}>
            {content.slides.map(({ title }, i) => (
              <span className={clsx(styles.step, activeSlide === i && styles.active)} key={title} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};
