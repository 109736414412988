import clsx from 'clsx';
import React, { forwardRef } from 'react';
import { BaseLink } from '../BaseLink';
import * as styles from './ButtonLink.module.css';

import type { ForwardedRef } from 'react';
import type { BaseLinkProps } from '../BaseLink';
import { TrackedButton } from '@objectiv/tracker-react';

export type SharedButtonProps = {
  hideHoverStateOnFocus?: boolean;
  theme?: 'drive' | 'dark' | 'outline';
  outline?: boolean;
};

export const Button = forwardRef(
  (
    {
      children,
      className,
      hideHoverStateOnFocus = false,
      theme = 'drive',
      outline,
      disabled,
      trackingId,
      ...props
    }: SharedButtonProps & React.ComponentPropsWithoutRef<'button'> & { trackingId?: string },
    ref?: ForwardedRef<HTMLButtonElement>,
  ): JSX.Element => (
    <TrackedButton
      ref={ref}
      className={clsx(
        styles.button,
        outline && styles.outline,
        hideHoverStateOnFocus && styles.hideHoverStateOnFocus,
        className,
      )}
      disabled={disabled}
      objectiv={{ id: trackingId || props.id }}
      {...props}
    >
      {children}
    </TrackedButton>
  ),
);

export const ButtonLink = forwardRef(
  (
    {
      children,
      className,
      hideHoverStateOnFocus = false,
      theme = 'drive',
      outline,
      ...props
    }: SharedButtonProps & BaseLinkProps,
    ref?: ForwardedRef<HTMLAnchorElement>,
  ): JSX.Element => (
    <BaseLink
      ref={ref}
      className={clsx(
        styles.button,
        styles.link,
        outline && styles.outline,
        hideHoverStateOnFocus && styles.hideHoverStateOnFocus,
        className,
      )}
      {...props}
    >
      {children}
    </BaseLink>
  ),
);
