/**
 * File containing used commands with payloads and responses
 * also provides the client to make the command calls
 * TODO: Share commands with backend
 */
import { fetchWithTimeout } from '~utils/fetchWithTimeout';

import type { LiabilityPricingEstimate } from '~models/liabilityPricingEstimate';

export type CommandResponses<CommandType extends string | number | symbol> = Record<CommandType, void> & {
  requestLiabilityPricingEstimate: LiabilityPricingEstimate | void;
};

const queue: string[] = [];

type DispatchCommandProps<MarketingCommands, CommandType extends keyof MarketingCommands> = {
  prospectId?: string;
  commandType: CommandType;
  command: MarketingCommands[CommandType];
  baseUrl: string;
  endPoint: string;
  meta?: { [key: string]: string };
};

export const dispatchCommand = async <MarketingCommands, CommandType extends keyof MarketingCommands>({
  prospectId,
  commandType,
  command,
  baseUrl,
  endPoint,
  meta,
}: DispatchCommandProps<MarketingCommands, CommandType>): Promise<{
  response: CommandResponses<CommandType>[CommandType];
  queueEmpty: boolean;
}> => {
  const ticket = `${new Date().getTime()}-${commandType as string}`;
  queue.push(ticket);

  if (!prospectId) {
    throw Error(`prospectId is not defined for ${JSON.stringify(commandType)} (yet)`);
  }

  // leverages event loop for locking, throws error when has been locked for > 10 seconds
  for (let i = 0; queue[0] !== ticket; i++) {
    if (i >= 100) {
      throw Error(`Potential command pipe deadlock for prospect=${prospectId}`);
    }
    await new Promise((resolve) => {
      setTimeout(resolve, 100);
    });
  }

  try {
    const response = await fetchWithTimeout(baseUrl + endPoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        type: commandType,
        payload: command,
        meta: meta,
      }),
      timeout: 10000,
    }).then((res) => res.text() as any);

    return {
      response,
      queueEmpty: queue.length === 1,
    };
  } finally {
    queue.shift();
  }
};
