export type PartialUtm = {
  source?: string;
  medium?: string;
  campaign?: string;
  term?: string;
};

export type FinomClientId = string;
type FacebookClickId = string;
type GoogleClickId = string;

export type MarketingParams = {
  utm?: PartialUtm;
  finomClientId?: FinomClientId;
  fbclid?: FacebookClickId;
  gclid?: GoogleClickId;
};

export const getMarketingParamsFromLocationSearch = (locationSearch: string): MarketingParams => {
  const urlParams = new URLSearchParams(locationSearch);
  const source = urlParams.get('utm_source');
  const medium = urlParams.get('utm_medium');
  const campaign = urlParams.get('utm_campaign');
  const term = urlParams.get('utm_term');
  const finomClientId = urlParams.get('client_id') ?? urlParams.get('id');
  const fbclid = urlParams.get('fbclid');
  const gclid = urlParams.get('gclid');
  let utm: MarketingParams['utm'] | null = null;
  if (source || medium || campaign || term) {
    utm = {
      ...(source && { source }),
      ...(medium && { medium }),
      ...(campaign && { campaign }),
      ...(term && { term }),
    };
  }

  return {
    ...(utm && { utm }),
    ...(finomClientId && { finomClientId }),
    ...(fbclid && { fbclid }),
    ...(gclid && { gclid }),
  };
};

export function getMarketingParams(): MarketingParams | undefined {
  if (typeof window !== 'undefined') {
    return getMarketingParamsFromLocationSearch(window.location.search);
  }
  return undefined;
}
